import { useState, useEffect } from 'react'

import { getUserTransactions } from '../../api'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

console.log(process.env.REACT_APP_API)

interface UserTransaction {
  date: Date
  agentId: string
  agentCode: string
  userId: string
  type: string
  summaryId: string
  transId: string
  amount: number
  remain: number
  debug: number
  status?: string
  error?: string
}

function UserTransactionsPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const username = searchParams.get('username') ?? undefined

  const roundId = searchParams.get('roundId') ?? undefined
  console.log('UserTransactionsPage', username, roundId)

  const [tempUsername, setTempUsername] = useState(username)

  const [tempRoundId, setTempRoundId] = useState(roundId)

  const [needUpdate, setNeedUpdate] = useState(false)
  const [transactions, setTransactions] = useState<UserTransaction[]>([])

  function fetchCondtion() {
    return username != null || roundId != null
  }

  async function fetchData() {
    if (fetchCondtion() === false) {
      return
    }

    const params = {
      username,
      roundId,
    }

    console.log(params)

    try {
      const rawData = await getUserTransactions(params)
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const datas = rawDatas.map((value, idx) => {
        return { ...value, idx: idx + 1, date: new Date(value.date) }
      })

      setTransactions(datas)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    console.log(fetchCondtion())
    if (fetchCondtion()) {
      fetchData()
    }
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    if (fetchCondtion()) {
      fetchData()
    }
    //fetchData({date:currentDate})
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1300px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          width: '100%',
        }}
      >
        <TextField
          style={{ maxWidth: '150px', margin: '0px 10px' }}
          id="outlined-basic"
          label="username"
          fullWidth
          value={tempUsername}
          onChange={(evt) => {
            setTempUsername(evt.target.value as string)
            //searchParams.set('username', evt.target.value as string)
            //setSearchParams(searchParams)
          }}
        />
        <TextField
          style={{ maxWidth: '300px', margin: '0px 10px' }}
          id="outlined-basic"
          label="roundId"
          fullWidth
          value={tempRoundId}
          onChange={(evt) => {
            setTempRoundId(evt.target.value as string)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            if (tempUsername !== '' && tempUsername != null) {
              searchParams.set('username', tempUsername ?? '')
            } else {
              searchParams.delete('username')
            }
            if (tempRoundId !== '' && tempRoundId != null) {
              searchParams.set('roundId', tempRoundId ?? '')
            } else {
              searchParams.delete('roundId')
            }
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
      </div>
      <Table sx={{ maxWidth: 1500, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">시간</TableCell>
            <TableCell align="center" width="30px">
              AgentId
            </TableCell>
            <TableCell align="center" width="30px">
              AgentCode
            </TableCell>
            <TableCell align="center" width="30px">
              UserId
            </TableCell>
            <TableCell align="center" width="30px">
              타입
            </TableCell>
            <TableCell align="center">SummaryId</TableCell>
            <TableCell align="center">트랜잭션Id</TableCell>
            <TableCell align="center">추가 밸런스</TableCell>
            <TableCell align="center">계산된 밸런스</TableCell>
            <TableCell align="center">트랜잭션 밸런스</TableCell>
            <TableCell align="center">상태</TableCell>
            <TableCell align="center">에러</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{row.date?.toLocaleString()}</TableCell>
              <TableCell align="left">{row.agentId}</TableCell>
              <TableCell align="left">{row.agentCode}</TableCell>
              <TableCell align="left">{row.userId}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.summaryId}</TableCell>
              <TableCell align="left">{row.transId}</TableCell>
              <TableCell align="right">{row.amount.toLocaleString()}</TableCell>
              <TableCell align="right">{row.remain?.toLocaleString()}</TableCell>
              <TableCell align="right">{row.debug?.toLocaleString()}</TableCell>
              <TableCell align="center">{row.status ?? ''}</TableCell>
              <TableCell align="center">{row.error ?? ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default UserTransactionsPage
