import React, { useState, useEffect } from 'react'

import { DataGrid, GridColumns } from '@mui/x-data-grid'

import { getAwsIpSet, getWhitelists, postAPIWhitelist, postBOWhitelist } from '../../api'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'

console.log(process.env.REACT_APP_API)

function ChangeAPIWhitelistDialog(props: { onClose: (updated: boolean) => void; agent: any; open: boolean }) {
  const { onClose, agent, open } = props

  const [whitelist, setWhitelist] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // open으로 열렸을 때만 초기화한다.
    if (open) {
      setWhitelist(agent.api_whitelist)
    }
  }, [open])

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>API 화이트리스트 변경</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="Whitelist"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            value={whitelist}
            onChange={(event) => {
              setWhitelist(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={async (e: any) => {
              console.log('postWhitelist')
              console.log(whitelist)
              setLoading(true)
              let updated = false
              try {
                const res = await postAPIWhitelist({ agentId: agent.agentId, whitelist })
                console.log(res)
                if (res.status === 0) {
                  updated = true
                } else {
                  alert(res.message ?? 'fail updated')
                }
              } catch (err) {
                console.log(err)
                alert('fail updated')
              }
              onClose(updated)

              setLoading(false)
            }}
          >
            변경
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
function ChangeBOWhitelistDialog(props: { onClose: (updated: boolean) => void; agent: any; open: boolean }) {
  const { onClose, agent, open } = props

  const [whitelist, setWhitelist] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // open으로 열렸을 때만 초기화한다.
    if (open) {
      setWhitelist(agent.bo_whitelist)
    }
  }, [open])

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>BO 화이트리스트 변경</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="Whitelist"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            value={whitelist}
            onChange={(event) => {
              setWhitelist(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={async (e: any) => {
              console.log('postWhitelist')
              console.log(whitelist)
              setLoading(true)
              let updated = false
              try {
                const res = await postBOWhitelist({ agentId: agent.agentId, whitelist })
                console.log(res)
                if (res.status === 0) {
                  updated = true
                } else {
                  alert(res.message ?? 'fail updated')
                }
              } catch (err) {
                console.log(err)
                alert('fail updated')
              }
              onClose(updated)

              setLoading(false)
            }}
          >
            변경
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function WhitelistPage() {
  const [needUpdate, setNeedUpdate] = useState(false)
  const [agentId, setAgentId] = useState('')
  const [loading, setLoading] = useState(false)
  const [agents, setAgents] = useState<any[]>([])
  const [awsIpSet, setAwsIpSets] = useState<string[]>([])
  const [selectedAgent, setSelectedAgent] = React.useState<any>({})
  const [openChangeAPIWhitelistDialog, setOpenChangeAPIWhitelistDialog] = React.useState(false)
  const [openChangeBOWhitelistDialog, setOpenChangeBOWhitelistDialog] = React.useState(false)
  const [openAwsIPSetDialog, setOpenAwsIPSetDialog] = React.useState(false)

  function fetchCondtion() {
    return agentId !== ''
  }

  async function fetchData() {
    if (fetchCondtion() === false) {
      return alert('검색조건 하나는 꼭 필요합니다.')
    }

    setLoading(true)

    const params = {
      ...(agentId !== '' && { agentId }),
    }

    try {
      const rawData = await getWhitelists(params)
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const { agents, whitelists } = rawData.data

      const datas = agents.map((value) => ({
        ...value,
        ...(whitelists.find((whitelist) => whitelist.agentId === value.agentId) ?? {}),
      }))
      setAgents(datas)
      setLoading(false)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  async function fetchAwsIpSet() {
    try {
      const rawData = await getAwsIpSet()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setAwsIpSets(rawData.data)
      setOpenAwsIPSetDialog(true)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    if (fetchCondtion()) {
      fetchData()
    }
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    if (fetchCondtion()) {
      fetchData()
    }
    //fetchData({date:currentDate})
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          width: '100%',
        }}
      >
        <TextField
          style={{ maxWidth: '100px', margin: '0px 10px' }}
          id="outlined-basic"
          label="agentId"
          fullWidth
          value={agentId}
          onChange={(evt) => {
            setAgentId(evt.target.value)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
        <Button
          style={{ minWidth: '100px', marginLeft: 'auto' }}
          variant="contained"
          onClick={() => {
            fetchAwsIpSet()
          }}
        >
          {'AWS IpSet'}
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>AgentId</TableCell>
            <TableCell>Nick</TableCell>
            <TableCell align="center">API 화이트리스트</TableCell>
            <TableCell align="center">백오피스 화이트리스트</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agents.map((agent) => (
            <TableRow key={agent.idx}>
              <TableCell width="100px">{agent.agentId}</TableCell>
              <TableCell width="100px">{agent.nick}</TableCell>
              <TableCell width="300px">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <div style={{ marginRight: '10px' }}>{agent.api_whitelist}</div>

                  <Button
                    variant="contained"
                    color="success"
                    onClick={(e: any) => {
                      e.stopPropagation() // don't select this row after clicking
                      setSelectedAgent(agent)
                      setOpenChangeAPIWhitelistDialog(true)
                    }}
                  >
                    API 변경
                  </Button>
                </div>
              </TableCell>
              <TableCell width="300px">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <div style={{ marginRight: '10px' }}>{agent.bo_whitelist}</div>

                  <Button
                    variant="contained"
                    color="success"
                    onClick={(e: any) => {
                      e.stopPropagation() // don't select this row after clicking
                      setSelectedAgent(agent)
                      setOpenChangeBOWhitelistDialog(true)
                    }}
                  >
                    백오피스 변경
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ChangeAPIWhitelistDialog
        open={openChangeAPIWhitelistDialog}
        agent={selectedAgent}
        onClose={(updated: boolean) => {
          setOpenChangeAPIWhitelistDialog(false)
          if (updated) setNeedUpdate(true)
        }}
      />
      <ChangeBOWhitelistDialog
        open={openChangeBOWhitelistDialog}
        agent={selectedAgent}
        onClose={(updated: boolean) => {
          setOpenChangeBOWhitelistDialog(false)
          if (updated) setNeedUpdate(true)
        }}
      />

      <Dialog onClose={() => setOpenAwsIPSetDialog(false)} open={openAwsIPSetDialog} fullWidth maxWidth="sm">
        <DialogTitle>AWS IpSet에 등록된 ip들</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {awsIpSet.map((value) => (
              <div>{value}</div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default WhitelistPage
