import { useState, useEffect } from 'react'

import { getUserTransactions, getUserTransfers } from '../../api'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

console.log(process.env.REACT_APP_API)

interface UserTransaction {
  id: string

  type: string

  agentId: string

  userId: string

  balance: number

  bonusBalance?: number

  affterBalance: number

  affterUserBalance: number

  receipt: string

  regDate: Date

  giveId: string

  targetId: string

  trees: unknown

  ms: string

  ip: string
}

function UserTransfersPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const username = searchParams.get('username') ?? undefined

  const ip = searchParams.get('ip') ?? undefined
  console.log('UserTransfersPage', username, ip)

  const [tempUsername, setTempUsername] = useState(username)

  const [tempIp, setTempIp] = useState(ip)

  const [needUpdate, setNeedUpdate] = useState(false)
  const [transactions, setTransactions] = useState<UserTransaction[]>([])

  const typeTables = {
    agent_give_balance: '유저 지급',
    agent_take_balance: '유저 회수',
  } as Record<string, string>

  function fetchCondtion() {
    return username != null || ip != null
  }

  async function fetchData() {
    if (fetchCondtion() === false) {
      return
    }

    const params = {
      username,
      ip,
    }

    console.log(params)

    try {
      const rawData = await getUserTransfers(params)
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const datas = rawDatas.map((value, idx) => {
        return { ...value, idx: idx + 1, regDate: new Date(value.regDate) }
      })

      setTransactions(datas)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    console.log(fetchCondtion())
    if (fetchCondtion()) {
      fetchData()
    }
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    if (fetchCondtion()) {
      fetchData()
    }
    //fetchData({date:currentDate})
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1300px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0px',
          width: '100%',
        }}
      >
        <TextField
          style={{ maxWidth: '150px', margin: '0px 10px' }}
          id="outlined-basic"
          label="username"
          fullWidth
          value={tempUsername}
          onChange={(evt) => {
            setTempUsername(evt.target.value as string)
            //searchParams.set('username', evt.target.value as string)
            //setSearchParams(searchParams)
          }}
        />
        <TextField
          style={{ maxWidth: '300px', margin: '0px 10px' }}
          id="outlined-basic"
          label="IP"
          fullWidth
          value={tempIp}
          onChange={(evt) => {
            setTempIp(evt.target.value as string)
          }}
        />
        <Button
          style={{ minWidth: '100px' }}
          variant="contained"
          onClick={() => {
            if (tempUsername !== '' && tempUsername != null) {
              searchParams.set('username', tempUsername ?? '')
            } else {
              searchParams.delete('username')
            }
            if (tempIp !== '' && tempIp != null) {
              searchParams.set('ip', tempIp ?? '')
            } else {
              searchParams.delete('ip')
            }
            setSearchParams(searchParams)
            setNeedUpdate(true)
          }}
        >
          {'검색'}
        </Button>
      </div>
      <Table sx={{ maxWidth: 1500, marginLeft: '10px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">시간</TableCell>
            <TableCell align="center" width="30px">
              AgentId
            </TableCell>
            <TableCell align="center" width="30px">
              UserId
            </TableCell>
            <TableCell align="center" width="30px">
              타입
            </TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">유저 밸런스</TableCell>
            <TableCell align="center">Message</TableCell>
            <TableCell align="center">Ip</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{row.regDate?.toLocaleString()}</TableCell>
              <TableCell align="left">{row.agentId}</TableCell>
              <TableCell align="left">{row.userId}</TableCell>
              <TableCell align="left">{typeTables[row.type] ?? row.type}</TableCell>
              <TableCell align="right">{(-row.balance).toLocaleString()}</TableCell>
              <TableCell align="right">{row.affterUserBalance?.toLocaleString()}</TableCell>
              <TableCell align="center">{row.ms ?? ''}</TableCell>
              <TableCell align="center">{row.ip ?? ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default UserTransfersPage
