import { useState, useEffect } from 'react'

import { getManualSettlementLog } from '../../api'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

console.log(process.env.REACT_APP_API)

function ManualSettlementLog() {
  const [loading, setLoading] = useState(false)
  const [logs, setLogs] = useState<any[]>([])
  console.log('ManualSettlementLogPage : ')

  async function fetchData() {
    try {
      setLoading(true)
      const rawData = await getManualSettlementLog({})
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      const data = rawDatas.map((value) => ({
        ...value,
        createdAt: new Date(value.createdAt),
      }))

      console.log(data)

      setLogs(data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
  }, [])

  return (
    <div
      className="Log"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%', minWidth: '1200px' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>IP</TableCell>
            <TableCell>마감일</TableCell>
            <TableCell>변경자</TableCell>
            <TableCell>에이전트 코드</TableCell>
            <TableCell>유저 ID</TableCell>
            <TableCell>벤더</TableCell>
            <TableCell>SummaryId ID</TableCell>
            <TableCell>금액</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => (
            <TableRow>
              <TableCell>{log.ip}</TableCell>
              <TableCell>{log.createdAt.toLocaleString()}</TableCell>
              <TableCell>{log.masterId}</TableCell>
              <TableCell>{log.body.agentCode}</TableCell>
              <TableCell>{log.body.userId}</TableCell>
              <TableCell>{log.body.vendor}</TableCell>
              <TableCell>{log.body.summaryId}</TableCell>
              <TableCell>{log.body.amount?.toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default ManualSettlementLog
