import * as qs from 'querystring'
import cookie from 'react-cookies'
import {
  Agent,
  AgentGameSettingRes,
  AgentLoginLog,
  AgentVendorSetting,
  ApiResponse,
  BettingItem,
  DailyFakeStat,
  DailyFakeUser,
  DailyNonFakeAgent,
  DailyNonFakeTable,
  EntryLog,
  EvoTable,
  FakeBetData,
  FakeChatLog,
  FakeConnectionLog,
  FakeGameData,
  FakeSeamlessStat,
  FakeSocketStat,
  GameItem,
  LogDoc,
  ManualSettlementLog,
  StatsAgentVendorUsage,
  User,
  VendorItem,
  WhitelistPacket,
} from './api.interface'
import { makeClearanceParams, querystring } from './util'

const apiUrl = process.env.REACT_APP_API ?? ''

async function customFetch(url: string, body?: any) {
  //const authorization = window.localStorage.getItem('authorization')

  const authorization = cookie.load('authorization')

  const res = await fetch(url, {
    method: body != null ? 'POST' : 'GET',
    body: body != null ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      ...(authorization != null && { authorization: authorization }),
    },
  })

  const rawData = await res.json()

  // Invalid Session
  if (rawData.status === 1015) {
    alert('세션이 만료되었습니다. 다시 로그인해주세요.')
    cookie.remove('authorization')
    window.location.href = '/'
  }

  return rawData
}

export async function masterLogin(params: { account: string; password: string }): Promise<
  ApiResponse<{
    session: string
  }>
> {
  const url = `${apiUrl}/api/v1/login`
  const rawData = await customFetch(url, params)

  return rawData
}

export async function getLogs(params: {
  date: string
  type: string
  userId?: string
  agentId?: string
  gameCompany?: string
  lastStep?: string
  onlyFail?: boolean
}): Promise<LogDoc[]> {
  //const searchParams = new URLSearchParams(params);
  const paramsStr = qs.stringify(params)

  console.log(`${apiUrl}/logs` + (paramsStr !== '' ? `?${paramsStr}` : ''))

  const rawData = await customFetch(`${apiUrl}/logs` + (paramsStr !== '' ? `?${paramsStr}` : ''))
  return rawData
}

export async function getPayments(): Promise<LogDoc[]> {
  //const searchParams = new URLSearchParams(params);
  const paramsStr = qs.stringify({})

  console.log(`${apiUrl}/logPayments` + (paramsStr !== '' ? `?${paramsStr}` : ''))

  const rawData = await customFetch(`${apiUrl}/logPayments` + (paramsStr !== '' ? `?${paramsStr}` : ''))
  return rawData
}

export async function getUsers(params: { userId?: string; agentId?: string; master?: string }): Promise<User[]> {
  //const searchParams = new URLSearchParams(params);
  const paramsStr = qs.stringify(params)

  console.log(`${apiUrl}/users` + (paramsStr !== '' ? `?${paramsStr}` : ''))

  const rawData = await customFetch(`${apiUrl}/users` + (paramsStr !== '' ? `?${paramsStr}` : ''))
  return rawData
}

export async function depositUserBalance(params: { userIdx: number; amount: number }): Promise<void> {
  await customFetch(`${apiUrl}/depositUserBalance`, params)
}

export async function withdrawUserBalance(params: { userIdx: number; amount: number }): Promise<void> {
  await customFetch(`${apiUrl}/withdrawUserBalance`, params)
}

export async function getGames(vendor: string): Promise<ApiResponse<GameItem[]>> {
  const url = `${apiUrl}/api/v1/games?vendor=${vendor}`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function gamesMoveToTop(vendor: string, code: string): Promise<ApiResponse<GameItem>> {
  const url = `${apiUrl}/api/v1/game/moveToTop`
  console.log(url)

  const rawData = await customFetch(url, { vendor, code })
  return rawData
}
export async function gamesMoveToBottom(vendor: string, code: string): Promise<ApiResponse<GameItem>> {
  const url = `${apiUrl}/api/v1/game/moveToBottom`
  console.log(url)

  const rawData = await customFetch(url, { vendor, code })
  return rawData
}

export async function modifyGame(params: {
  _id: string
  setObj: { used?: string; nameKo?: string }
}): Promise<ApiResponse<VendorItem[]>> {
  const url = `${apiUrl}/api/v1/game/modify`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function uploadGameImage(id: string, image: string): Promise<ApiResponse<string>> {
  const url = `${apiUrl}/api/v1/uploadGameImage`
  console.log(id)

  const rawData = await customFetch(url, { id, image })
  return rawData
}

export async function getNewGames(month: string): Promise<ApiResponse<GameItem[]>> {
  const url = `${apiUrl}/api/v1/new-games?month=${month}`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getVendors(): Promise<ApiResponse<VendorItem[]>> {
  const url = `${apiUrl}/api/v1/vendors`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function setVendorUsed(id: string, used: boolean): Promise<ApiResponse<VendorItem[]>> {
  const url = `${apiUrl}/api/v1/vendorUsed`
  console.log(url)

  const rawData = await customFetch(url, { id, used })
  return rawData
}

export async function updateGameListExcel(code: string, excel: string): Promise<ApiResponse<VendorItem[]>> {
  const url = `${apiUrl}/api/v1/updateGameListExcel`
  console.log(url)

  const rawData = await customFetch(url, { code, excel })
  return rawData
}

export async function updateGameListApi(code: string): Promise<ApiResponse<VendorItem[]>> {
  const url = `${apiUrl}/api/v1/updateGameListApi`
  console.log(url)

  const rawData = await customFetch(url, { code })
  return rawData
}

export async function getAwsIpSet(): Promise<ApiResponse<string[]>> {
  //const searchParams = new URLSearchParams(params);
  const url = `${apiUrl}/api/v1/awsIpSet`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getWhitelists(params: {
  agentId?: string
  master?: string
}): Promise<ApiResponse<WhitelistPacket>> {
  //const searchParams = new URLSearchParams(params);
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/whitelists` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function postAPIWhitelist(params: { agentId: string; whitelist: string }): Promise<ApiResponse> {
  const url = `${apiUrl}/api/v1/api_whitelist`
  const rawData = await customFetch(url, params)
  return rawData
}

export async function postBOWhitelist(params: { agentId: string; whitelist: string }): Promise<ApiResponse> {
  const url = `${apiUrl}/api/v1/bo_whitelist`
  const rawData = await customFetch(url, params)
  return rawData
}

export async function getCancelledBettings(params: {
  vendor?: string
  continueId?: string
}): Promise<ApiResponse<BettingItem[]>> {
  const paramsStr = qs.stringify({ ...params, limit: 1000, amountWinMin: 0 })

  const url = `${apiUrl}/api/v1/bettings/cancel` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getGameRounds(params: { tableId?: string }): Promise<ApiResponse<FakeGameData[]>> {
  const paramsStr = qs.stringify({ ...params, limit: 500 })

  const url = `${apiUrl}/api/v1/fake/gameRounds` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getFakeEntryErrorLogs(): Promise<ApiResponse<any[]>> {
  const url = `${apiUrl}/api/v1/fake/log/entry-error`

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getFakeConnectionLogs(params: { username?: string }): Promise<ApiResponse<FakeConnectionLog[]>> {
  const paramsStr = qs.stringify({ ...params })
  const url = `${apiUrl}/api/v1/fake/log/connection` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getFakeChatLogs(): Promise<ApiResponse<FakeChatLog[]>> {
  const url = `${apiUrl}/api/v1/fake/log/chat`

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}
export async function getEntryLogs(params: {
  vendor?: string
  agentCode?: string
  userId?: string
  error?: boolean
}): Promise<ApiResponse<EntryLog[]>> {
  const paramsStr = qs.stringify(makeClearanceParams(params))
  const url = `${apiUrl}/api/v1/entry-log` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getManualSettlementLog(params: any): Promise<ApiResponse<ManualSettlementLog[]>> {
  const paramsStr = qs.stringify(makeClearanceParams(params))
  const url = `${apiUrl}/api/v1/manual-settlement-log` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getBets(params: {
  summaryId?: string
  agentId?: string
  userId?: string
  gameId?: string
  vendor?: string
  startDate?: string
  endDate?: string
  betStatus?: string
  continueId?: string
}): Promise<ApiResponse<BettingItem[]>> {
  //const searchParams = new URLSearchParams(params);

  const paramsStr = qs.stringify(makeClearanceParams(params))

  const url = `${apiUrl}/api/v1/casino/bets` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getCasinoBet(params: { objectId: string }): Promise<ApiResponse<BettingItem>> {
  //const searchParams = new URLSearchParams(params);

  const paramsStr = qs.stringify(makeClearanceParams(params))

  const url = `${apiUrl}/api/v1/casino/bet` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getSlotBet(params: { objectId: string }): Promise<ApiResponse<BettingItem>> {
  //const searchParams = new URLSearchParams(params);

  const paramsStr = qs.stringify(makeClearanceParams(params))

  const url = `${apiUrl}/api/v1/slot/bet` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getFakeBets(params: {
  userId?: string
  gameId?: string
  roundId?: string
  continueId?: string
}): Promise<ApiResponse<FakeBetData[]>> {
  //const searchParams = new URLSearchParams(params);

  const paramsStr = qs.stringify(makeClearanceParams(params))

  const url = `${apiUrl}/api/v1/fake/bets` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getFakeBet(params: { username: string; roundId: string }): Promise<
  ApiResponse<{
    bet: BettingItem
    fake: FakeBetData
    game: FakeGameData
  }>
> {
  //const searchParams = new URLSearchParams(params);

  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/fake/bet` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function fakeGameData(params: { roundId: string }): Promise<ApiResponse<any>> {
  //const searchParams = new URLSearchParams(params);

  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/fake/gameData` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function settlementBet(params: {
  agentCode: string
  userId: string
  vendor: string
  summaryId: string
  amount: number
}): Promise<ApiResponse<BettingItem[]>> {
  const url = `${apiUrl}/api/v1/casino/settlement`
  const rawData = await customFetch(url, params)
  return rawData
}

export async function modifyBet(params: {
  _id: string
  setObj: { balanceEnd?: number; amountBet?: number; amountWin?: number }
}): Promise<ApiResponse<BettingItem[]>> {
  const url = `${apiUrl}/api/v1/casino/bet/modify`
  const rawData = await customFetch(url, params)

  return rawData
}

export async function getUserTransactions(params: {
  username?: string
  roundId?: string
}): Promise<ApiResponse<any[]>> {
  const paramsStr = querystring(params)

  const url = `${apiUrl}/api/v1/user/transactions` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getUserTransfers(params: { username?: string; ip?: string }): Promise<ApiResponse<any[]>> {
  const paramsStr = querystring(params)

  const url = `${apiUrl}/api/v1/user/transfers` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getAgentTransactions(params: { agentId?: string }): Promise<ApiResponse<any[]>> {
  const paramsStr = querystring(params)

  const url = `${apiUrl}/api/v1/agent/transactions` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getEvoTables(): Promise<ApiResponse<EvoTable[]>> {
  const url = `${apiUrl}/api/v1/evo-tables`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getFakeSockets(params: {
  cluster: string
  serviceName: string
  region: string
}): Promise<ApiResponse<FakeSocketStat[]>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/fake-sockets` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export interface FakeBlacklistUser {
  agentId: string
  username: string
  ip: string
  headers: { [key: string]: string }
  createdAt: Date
}

export async function getBlacklistUsers(): Promise<ApiResponse<FakeBlacklistUser[]>> {
  const url = `${apiUrl}/api/v1/fake/blacklistUsers`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}
export async function registerBlacklistUser(params: {
  username: string
  ip: string
  headers: { [key: string]: string }
}): Promise<ApiResponse<FakeSocketStat[]>> {
  const url = `${apiUrl}/api/v1/fake/blacklistUser`
  const rawData = await customFetch(url, params)

  return rawData
}
export async function deleteBlacklistUser(params: { username: string }): Promise<ApiResponse<FakeSocketStat[]>> {
  const url = `${apiUrl}/api/v1/fake/deleteBlacklistUser`
  const rawData = await customFetch(url, params)

  return rawData
}

export async function getFakeSeamless(params: {
  cluster: string
  serviceName: string
  region: string
}): Promise<ApiResponse<FakeSeamlessStat[]>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/fake-seamless` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export interface RedirectInfo {
  idx: number
  agentCode: string
  agentId: string
  nick: string
  redirectTo: string
  redirectFrom: string
  redirectParam?: string
  group: boolean
  entryDomainSettings?: {
    [entryDomain: string]: {
      redirectTo?: string
      redirectDomain?: string
    }
  }
}

export async function getEvoRedirectInfo(): Promise<
  ApiResponse<{
    lists: RedirectInfo[]
    redirectUrls: string[]
  }>
> {
  const url = `${apiUrl}/api/v1/evo-redirect/info`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export interface FakeDomain {
  domainName: string
  redirectServer?: string
  cert?: {
    CertificateArn: string
    Status: string
  }
  hostedZone?: {
    Id: string
    Name: string
  }
}

export async function getFakeDomains(): Promise<ApiResponse<FakeDomain[]>> {
  const url = `${apiUrl}/api/v1/fake-domains`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getFakeDomainDetail(arn: string): Promise<
  ApiResponse<
    {
      validationRecord?: {
        Name: string
        Value: string
      }
      subDomainNames?: {
        DomainName: string
        /**
         * The domain name configurations.
         */
        DomainNameConfigurations?: {
          ApiGatewayDomainName: string
          DomainNameStatus: string
        }[]
      }[]
    }[]
  >
> {
  const url = `${apiUrl}/api/v1/fake-domain/detail`
  console.log(url)

  const rawData = await customFetch(url, { arn })
  return rawData
}

interface CertDetail {
  CertificateArn: string // 'arn:aws:acm:ap-northeast-2:633624879762:certificate/d66a5a79-2178-499e-bcb9-560b7dc253ec'
  DomainName: string // '*.evolution-games.net'
  SubjectAlternativeNames: ['*.evolution-games.net']
  DomainValidationOptions: [
    {
      DomainName: string // '*.evolution-games.net'
      ValidationDomain: string // '*.evolution-games.net'
      ValidationStatus: string // 'PENDING_VALIDATION'
      ValidationMethod: string // 'DNS'
      ResourceRecord: {
        Name: string
        Value: string
      }
    },
  ]
  Subject: 'CN=*.evolution-games.net'
  Issuer: 'Amazon'
  CreatedAt: '2023-06-18T16:29:56.528Z'
  Status: 'PENDING_VALIDATION'
  KeyAlgorithm: 'RSA-2048'
  SignatureAlgorithm: 'SHA256WITHRSA'
  InUseBy: []
  Type: 'AMAZON_ISSUED'
  KeyUsages: []
  ExtendedKeyUsages: []
  RenewalEligibility: 'INELIGIBLE'
  Options: {
    CertificateTransparencyLoggingPreference: 'ENABLED'
  }
}

export async function postFakeDomainCert(domainName: string): Promise<ApiResponse<CertDetail>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/fake-domain/cert`
  console.log(url)

  const rawData = await customFetch(url, { domainName })
  return rawData
}

export async function postFakeDomainSubDomain(params: {
  fullDomain: string
  certArn: string
  apiId: string
  stage: string
}): Promise<
  ApiResponse<{
    DomainName: string
    /**
     * The domain name configurations.
     */
    DomainNameConfigurations?: {
      ApiGatewayDomainName: string
      DomainNameStatus: string
    }[]

    Items: {
      ApiId: string
      ApiMappingId: string
      Stage: string
    }[]
  }>
> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/fake-domain/subdomain`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function postFakeDomainHostedZoneRecord(params: {
  domainName: string
  hostedZoneId: string
  recordValue: string
}): Promise<ApiResponse<any>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/fake-domain/hostedZoneRecord`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function postEvoDomainRedirect(params: {
  domainName: string
  redirectServer: string
}): Promise<ApiResponse<void>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/fake-domain/redirect-server`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function postFakeUrl(params: { domainName: string }): Promise<ApiResponse<void>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/evo-redirect/fake-urls`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}
export async function deleteFakeUrl(params: { domainName: string }): Promise<ApiResponse<void>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/evo-redirect/fake-urls`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function postEvoRedirect(params: {
  agentCode: string
  redirectTo?: string
  fakeVendor?: string
  redirectParam?: string
}): Promise<ApiResponse<void>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/evo-redirect`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function postEvoSubRedirect(params: {
  agentCode: string
  entryDomain: string
  redirectDomain?: string
  redirectTo?: string
}): Promise<ApiResponse<void>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/evo-sub-redirect`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function setEvoRedirectGroup(params: { agentCode: string; group: boolean }): Promise<ApiResponse<void>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/evo-redirect/set-group`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}
export async function postGroupEvoRedirect(params: { redirectTo: string }): Promise<ApiResponse<void>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/evo-redirect/group-redirect`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function getAgents(params: { showAll?: boolean }): Promise<ApiResponse<Agent[]>> {
  //const searchParams = new URLSearchParams(params);
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/agents` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getAgentGameSetting(params: {
  agentCode?: string
  agentId?: string
}): Promise<ApiResponse<AgentGameSettingRes>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/agent/game/setting` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function setAgentVendorUsed(params: {
  agentId: string
  vendor: string
  used: boolean
}): Promise<ApiResponse<AgentVendorSetting[]>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/agent/vendor/used`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function setAgentGameAllowBet(params: {
  agentId: string
  vendor: string
  gameId: string
  allowBet: boolean
}): Promise<ApiResponse<AgentVendorSetting[]>> {
  const authorization = cookie.load('authorization')
  const url = `${apiUrl}/api/v1/agent/game/allowBet`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function setAgentVendorConfigValue(params: {
  agentId: string
  vendor: string
  config: {
    key: string
    value: unknown
  }
}): Promise<ApiResponse<AgentVendorSetting[]>> {
  const url = `${apiUrl}/api/v1/agent/vendor/configValue`
  console.log(url)

  const rawData = await customFetch(url, params)
  return rawData
}

export async function depositAgentBalance(params: { agentIdx: number; amount: number }): Promise<void> {
  const url = `${apiUrl}/depositAgentBalance`

  const rawData = await customFetch(url, params)
}

export async function withdrawAgentBalance(params: { agentIdx: number; amount: number }): Promise<void> {
  const url = `${apiUrl}/withdrawAgentBalance`
  const rawData = await customFetch(url, params)
}

export async function getCalculate(params: { master?: string; begin: string; end: string }): Promise<Agent[]> {
  //const searchParams = new URLSearchParams(params);
  const paramsStr = qs.stringify(params)

  console.log(`${apiUrl}/api/v1/calculates` + (paramsStr !== '' ? `?${paramsStr}` : ''))

  const rawData = await customFetch(`${apiUrl}/api/v1/calculates` + (paramsStr !== '' ? `?${paramsStr}` : ''))
  return rawData
}

export async function getFakeCalu(): Promise<ApiResponse<VendorItem[]>> {
  const url = `${apiUrl}/api/v1/getFakeCalu`
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getDailyFakeStats(params: { day: string }): Promise<ApiResponse<DailyFakeStat>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/daily-fake-stats` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getDailyNonFakeTables(params: { day: string }): Promise<ApiResponse<DailyNonFakeTable>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/daily-non-fake-tables` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getDailyNonFakeAgents(params: { day: string }): Promise<ApiResponse<DailyNonFakeAgent>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/daily-non-fake-agents` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getDailyFakeUsers(params: { day: string }): Promise<ApiResponse<DailyFakeUser>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/daily-fake-users` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getAgentVendorUsage(params: {
  day?: number
  agentId?: string
  includeSub?: boolean
  startDate?: string
  endDate?: string
}): Promise<ApiResponse<StatsAgentVendorUsage[]>> {
  const paramsStr = qs.stringify(params)

  const url = `${apiUrl}/api/v1/stats/agent-vendor-usage` + (paramsStr !== '' ? `?${paramsStr}` : '')
  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}

export async function getAgentLoginLogs(params: { agentId?: string | null }): Promise<ApiResponse<AgentLoginLog[]>> {
  const paramsStr = querystring(params)
  const url = `${apiUrl}/api/v1/agent-login-log` + (paramsStr !== '' ? `?${paramsStr}` : '')

  console.log(url)

  const rawData = await customFetch(url)
  return rawData
}
