import React, { useState, useEffect } from 'react'

import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { getBets, settlementBet, modifyBet, getVendors } from '../../api'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { setUrlSearchParamsValue } from '../../util'
import { BettingItem, VendorItem } from '../../api.interface'
import { FakeDetailDialog } from '../../component/FakeDetailDialog'
import { BetDetailDialog } from '../../component/BetDetailDialog'
import { format } from 'date-fns'

console.log(process.env.REACT_APP_API)

function SettleDialog(props: { onClose: (updated: boolean) => void; betting?: BettingItem; open: boolean }) {
  const { onClose, betting, open } = props

  const [settleMoney, setSettleMoney] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // open으로 열렸을 때만 초기화한다.
    if (open) {
      setSettleMoney(betting?.amountWin?.toString() ?? '')
    }
  }, [open])

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>마감</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="outlined-multiline-static"
            label="마감금액"
            fullWidth
            style={{
              marginRight: '10px',
            }}
            type="number"
            inputMode="decimal"
            value={settleMoney}
            onChange={(event) => {
              setSettleMoney(event.target.value)
            }}
          />
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={async (e: any) => {
              console.log(settleMoney)
              let updated = false
              try {
                const incAmount = Number(settleMoney)
                if (Number.isNaN(incAmount)) {
                  alert('금액이 잘못되었습니다.')
                  return
                }
                if (betting == null) {
                  return
                }

                setLoading(true)
                const { agentCode, userId, vendor, summaryId } = betting
                const res = await settlementBet({ agentCode, userId, vendor, summaryId, amount: Number(settleMoney) })
                console.log(res)
                if (res.status === 0) {
                  updated = true
                } else {
                  alert(res.message ?? 'fail updated')
                }
              } catch (err) {
                console.log(err)
                alert('fail updated')
              }
              onClose(updated)

              setLoading(false)
            }}
          >
            마감
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function ModifyDialog(props: {
  onClose: (setObj: Partial<BettingItem>) => void
  betting?: BettingItem
  open: boolean
}) {
  const { onClose, betting, open } = props

  const [loading, setLoading] = useState(false)
  const [newAmountBetTxt, setNewAmountBetTxt] = useState<string>()
  const [newAmountWinTxt, setNewAmountWinTxt] = useState<string>()
  const [newBalanceEndTxt, setNewBalanceEnd] = useState<string>()

  useEffect(() => {
    // open으로 열렸을 때만 초기화한다.
    if (open) {
      setNewAmountBetTxt(betting?.amountBet?.toString())
      setNewAmountWinTxt(betting?.amountWin?.toString())
      setNewBalanceEnd(betting?.balanceEnd?.toString())
    }
  }, [open])

  const handleClose = () => {
    onClose({})
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>변경</DialogTitle>
      <DialogContent>
        <div style={{ marginTop: '20px', marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
          <TextField
            id="outlined-multiline-static"
            label="베팅금"
            style={{
              width: '200px',
            }}
            type="number"
            inputMode="decimal"
            value={newAmountBetTxt}
            onChange={(event) => {
              setNewAmountBetTxt(event.target.value)
            }}
          />
          <div style={{ height: '30px' }} />
          <TextField
            id="outlined-multiline-static"
            label="마감금"
            style={{
              width: '200px',
            }}
            type="number"
            inputMode="decimal"
            value={newAmountWinTxt}
            onChange={(event) => {
              setNewAmountWinTxt(event.target.value)
            }}
          />
          <div style={{ height: '30px' }} />
          <TextField
            id="outlined-multiline-static"
            label="잔액"
            style={{
              width: '200px',
            }}
            type="number"
            inputMode="decimal"
            value={newBalanceEndTxt}
            onChange={(event) => {
              setNewBalanceEnd(event.target.value)
            }}
          />
          <div style={{ height: '30px' }} />

          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={async (e: any) => {
              console.log(newBalanceEndTxt)

              let setObj: Partial<BettingItem> = {}
              try {
                if (betting == null) {
                  onClose({})
                  return
                }

                const newAmountBet = Number(newAmountBetTxt)
                if (Number.isNaN(newAmountBet)) {
                  alert('금액이 잘못되었습니다.')
                  return
                }
                const newAmountWin = Number(newAmountWinTxt)
                if (Number.isNaN(newAmountWin)) {
                  alert('금액이 잘못되었습니다.')
                  return
                }
                const newBalance = Number(newBalanceEndTxt)
                if (Number.isNaN(newBalance)) {
                  alert('금액이 잘못되었습니다.')
                  return
                }

                if (newAmountBet !== betting.amountBet) {
                  setObj.amountBet = newAmountBet
                }
                if (newAmountWin !== betting.amountWin) {
                  setObj.amountWin = newAmountWin
                }
                if (newBalance !== betting.balanceEnd) {
                  setObj.balanceEnd = newBalance
                }

                console.log('setObj', JSON.stringify(setObj))

                if (Object.keys(setObj).length === 0) {
                  onClose({})
                  return
                }

                setLoading(true)

                const { _id } = betting
                const res = await modifyBet({ _id, setObj })
                console.log(res)
                if (res.status === 0) {
                } else {
                  alert(res.message ?? 'fail updated')
                  setObj = {}
                }
              } catch (err) {
                console.log(err)
                alert('fail updated')
              }
              onClose(setObj)

              setLoading(false)
            }}
          >
            변경
          </Button>
          <div style={{ height: '30px' }} />
        </div>
      </DialogContent>
    </Dialog>
  )
}

function FakeBettingSearch() {
  const [needUpdate, setNeedUpdate] = useState(false)
  const [summaryId, setSummaryId] = useState('')
  const [gameId, setGameId] = useState('')
  const [agentId, setAgentId] = useState('')
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  const [bettings, setBettings] = useState<BettingItem[]>([])
  const [totalBetMoney, setTotalBetMoney] = useState<number>(0)
  const [totalWinMoney, setTotalWinMoney] = useState<number>(0)
  const [totalWinRate, setTotalWinRate] = useState<number>(0)
  const [selectedBetting, setSelectedBetting] = React.useState<BettingItem>()
  const [showFakeField, setShowFakeField] = React.useState(false)
  const [openSettleDialog, setOpenSettleDialog] = React.useState(false)
  const [openModifyDialog, setOpenModifyDialog] = React.useState(false)
  const [openDetailDialog, setOpenDetailDialog] = React.useState(false)
  const [openFakeDetailDialog, setOpenFakeDetailDialog] = React.useState(false)
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const [searchParams, setSearchParams] = useSearchParams()
  const agentIdParam = searchParams.get('agentId') ?? undefined
  const userIdParam = searchParams.get('userId') ?? undefined
  const gameIdParam = searchParams.get('gameId') ?? undefined
  const summaryIdParam = searchParams.get('summaryId') ?? undefined
  const vendorParam = searchParams.get('vendor') ?? undefined
  const betStatusParam = searchParams.get('betStatus') ?? undefined
  const [vendors, setVendors] = useState<VendorItem[]>([])

  const logColumes: GridColumns = [
    {
      field: 'idx',
      headerName: '번호',
      align: 'center',
      headerAlign: 'center',
      width: 70,
    },
    {
      field: 'username',
      headerName: '유저',
      width: 120,
    },
    {
      field: 'summaryId',
      headerName: 'SummaryId',
      width: 450,
    },
    {
      field: 'fakeRoundId',
      headerName: 'fakeRoundId',
      width: 230,
    },
    {
      field: 'betTime',
      headerName: '베팅시간',
      width: 200,
      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'updatedAt',
      headerName: '마감시간',
      width: 200,
      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'amountBet',
      headerName: '베팅금',
      align: 'right',
      headerAlign: 'right',
      width: 120,

      valueFormatter: (params) => params.value?.toLocaleString(),
    },
    {
      field: 'amountWin',
      headerName: '적중금',
      align: 'right',
      headerAlign: 'right',
      width: 120,
      renderCell: (params) => {
        return params.value === params.row.amountOrgWin ? (
          <div>{params.value?.toLocaleString()}</div>
        ) : (
          <div>
            <span>{params.value?.toLocaleString()}</span>
            <span style={{ color: 'red', marginLeft: '5px' }}>({params.row.amountOrgWin?.toLocaleString()})</span>
          </div>
        )
      },
      /*valueFormatter: (params) => params.value !== params.row.amount
      params.value?.toLocaleString(),*/
    },
    {
      field: 'balanceEnd',
      headerName: '잔액',
      align: 'right',
      headerAlign: 'right',
      width: 150,
      valueFormatter: (params) => params.value?.toLocaleString(),
      /*renderCell: (params: GridRenderCellParams) => (
        <div style={{ width: '100%' }}>
          <TextField id="filled-basic" variant="outlined" value={params.value as any} />
        </div>
      ),*/
    },
    {
      field: 'betStatus',
      headerName: '상태',
      align: 'center',
      headerAlign: 'center',
      width: 100,
    },
    ...(showFakeField
      ? ([
          {
            field: 'betAccepted',
            headerName: '베팅 내역',
            align: 'right',
            headerAlign: 'right',
            width: 200,

            //valueFormatter: (params) => JSON.stringify(params.value ?? {}),
            renderCell: (params: GridRenderCellParams) => (
              <div style={{ width: '100%' }}>
                {Object.entries(params.value ?? {}).map(([key, value]) => {
                  return (
                    <div
                      id={key}
                      style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}
                    >
                      <div style={{ textAlign: 'left' }}>{key}</div>
                      <div style={{ textAlign: 'right' }}>{(value as number)?.toLocaleString() ?? value}</div>
                    </div>
                  )
                })}
              </div>
            ),
          },
          {
            field: 'betSettled',
            headerName: '마감 내역',
            align: 'right',
            headerAlign: 'right',
            width: 200,

            //valueFormatter: (params) => JSON.stringify(params.value ?? {}),
            renderCell: (params: GridRenderCellParams) => (
              <div style={{ width: '100%' }}>
                {Object.entries(params.value ?? {}).map(([key, value]) => {
                  return (
                    <div
                      id={key}
                      style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}
                    >
                      <div style={{ textAlign: 'left' }}>{key}</div>
                      <div style={{ textAlign: 'right' }}>{(value as any)?.payoff.toLocaleString() ?? value}</div>
                    </div>
                  )
                })}
              </div>
            ),
          },
          {
            field: 'fakeAmountBet',
            headerName: '페이크 베팅금',
            align: 'right',
            headerAlign: 'right',
            width: 120,

            valueFormatter: (params: any) => params.value?.toLocaleString(),
          },
          {
            field: 'fakeAmountWin',
            headerName: '페이크 적중금',
            align: 'right',
            headerAlign: 'right',
            width: 120,
            valueFormatter: (params: any) => params.value?.toLocaleString(),
          },
        ] as any)
      : []),
    {
      field: 'detailAction',
      headerName: '상세',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              color="success"
              onClick={(e: any) => {
                e.stopPropagation() // don't select this row after clicking
                setSelectedBetting(params.row)
                if (params.row.isFakeBet) {
                  setOpenFakeDetailDialog(true)
                } else {
                  setOpenDetailDialog(true)
                }
              }}
            >
              상세
            </Button>
          </div>
        )
      },
    },
    {
      field: 'modifyAction',
      headerName: '변경',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              color="success"
              onClick={(e: any) => {
                e.stopPropagation() // don't select this row after clicking
                setSelectedBetting(params.row)
                setOpenModifyDialog(true)
              }}
            >
              변경
            </Button>
          </div>
        )
      },
    },
    {
      field: 'settleAction',
      headerName: '마감',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              color="success"
              onClick={(e: any) => {
                e.stopPropagation() // don't select this row after clicking
                setSelectedBetting(params.row)
                if (params.row.betStatus !== 'BET') {
                  alert('베팅상태가 아닙니다.')
                  return
                }
                setOpenSettleDialog(true)
              }}
            >
              마감
            </Button>
          </div>
        )
      },
    },
  ]

  async function fetchVendors() {
    try {
      const rawData = await getVendors()
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setVendors(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  async function fetchData(continueData = false) {
    setLoading(true)

    try {
      const rawData = await getBets({
        ...(agentIdParam != null && { agentId: agentIdParam }),
        ...(userIdParam != null && { userId: userIdParam }),
        ...(gameIdParam != null && { gameId: gameIdParam }),
        ...(summaryIdParam != null && { summaryId: summaryIdParam }),
        ...(vendorParam != null && { vendor: vendorParam }),
        ...(betStatusParam != null && { betStatus: betStatusParam }),
        ...(startDate != null && { startDate: format(startDate, 'yyyy-MM-dd') }),
        ...(endDate != null && { endDate: format(endDate, 'yyyy-MM-dd') }),
        ...(continueData && bettings.length > 0 && { continueId: bettings[bettings.length - 1]._id }),
      })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const rawDatas = rawData.data

      let totalBetMoney = 0
      let totalWinMoney = 0
      let totalWinCount = 0
      let totalCount = 0

      const newData: BettingItem[] = rawDatas.map((value) => {
        return {
          ...value,
          betTime: new Date(value.betTime),
          updatedAt: new Date(value.updatedAt),
          username: value.agentCode + value.userId,
        }
      })

      const updateData = continueData ? [...bettings, ...newData] : newData

      for (const data of updateData) {
        const amountBet = data.amountBet ?? 0
        const amountWin = data.amountWin ?? 0
        totalBetMoney += amountBet
        totalWinMoney += amountWin

        if (amountBet < amountWin) {
          totalWinCount++
        }
        if (amountBet !== amountWin) {
          totalCount++
        }
      }

      const indexedDatas = updateData.map((value, idx) => {
        return { ...value, idx: idx + 1 }
      })

      setTotalBetMoney(totalBetMoney)
      setTotalWinMoney(totalWinMoney)
      setTotalWinRate(totalWinCount / totalCount)

      setBettings(indexedDatas)
      setLoading(false)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchVendors()
    setUserId(userIdParam ?? '')
    setSummaryId(summaryIdParam ?? '')
    setGameId(gameIdParam ?? '')
  }, [])

  return (
    <div className="Log" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '2000px' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '10px 0px',
          }}
        >
          <TextField
            style={{ maxWidth: '150px', margin: '0px 10px' }}
            id="outlined-basic"
            label="에이전트ID"
            fullWidth
            value={agentId}
            onChange={(evt) => {
              setAgentId(evt.target.value)
            }}
          />
          <TextField
            style={{ maxWidth: '150px', margin: '0px 10px' }}
            id="outlined-basic"
            label="유저ID"
            fullWidth
            value={userId}
            onChange={(evt) => {
              setUserId(evt.target.value)
            }}
          />
          <TextField
            style={{ maxWidth: '500px', margin: '0px 10px' }}
            id="outlined-basic"
            label="섬머리ID"
            fullWidth
            value={summaryId}
            onChange={(evt) => {
              setSummaryId(evt.target.value)
            }}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-standard-label">벤더</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={vendorParam}
              label="벤더"
              onChange={(event) => {
                if (event.target.value === 'all') {
                  searchParams.delete('vendor')
                } else {
                  searchParams.set('vendor', event.target.value as string)
                }

                setSearchParams(searchParams)
                setNeedUpdate(true)
              }}
            >
              <MenuItem key={''} value={'all'}>
                {`전체`}
              </MenuItem>
              {vendors.map((value) => (
                <MenuItem key={value.code} value={value.code}>
                  {`${value.nameKo} (${value.code})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            style={{ maxWidth: '200px', margin: '0px 10px' }}
            id="outlined-basic"
            label="게임ID"
            fullWidth
            value={gameId}
            onChange={(evt) => {
              setGameId(evt.target.value)
            }}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">상태</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={betStatusParam}
              label="상태"
              onChange={(event) => {
                if (event.target.value === 'all') {
                  searchParams.delete('betStatus')
                } else {
                  searchParams.set('betStatus', event.target.value as string)
                }

                setSearchParams(searchParams)
                setNeedUpdate(true)
              }}
            >
              <MenuItem key={''} value={'all'}>
                {`전체`}
              </MenuItem>
              <MenuItem key={'BET'} value={'BET'}>
                {`BET`}
              </MenuItem>
              <MenuItem key={'SETTLE'} value={'SETTLE'}>
                {`SETTLE`}
              </MenuItem>
              <MenuItem key={'CANCEL'} value={'CANCEL'}>
                {`CANCEL`}
              </MenuItem>
            </Select>
          </FormControl>
          <div style={{ minWidth: '200px' }}>
            <DatePicker
              label="시작 날짜"
              format="yyyy-MM-dd"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue ?? undefined)}
            />
          </div>
          <div style={{ minWidth: '200px' }}>
            <DatePicker
              label="종료 날짜"
              format="yyyy-MM-dd"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue ?? undefined)}
            />
          </div>
          <Button
            style={{ minWidth: '100px' }}
            variant="contained"
            onClick={() => {
              setUrlSearchParamsValue(searchParams, 'agentId', agentId)
              setUrlSearchParamsValue(searchParams, 'userId', userId)
              setUrlSearchParamsValue(searchParams, 'gameId', gameId)
              setUrlSearchParamsValue(searchParams, 'summaryId', summaryId)
              setSearchParams(searchParams)
              setNeedUpdate(true)
            }}
          >
            {'검색'}
          </Button>
          <Button
            style={{ minWidth: '100px', marginLeft: '10px' }}
            variant="contained"
            onClick={() => {
              fetchData(true)
            }}
          >
            {`데이터 추가 로딩 (${bettings.length})`}
          </Button>

          <FormControlLabel
            style={{ minWidth: '100px', margin: '0px 10px' }}
            control={
              <Checkbox
                value={showFakeField}
                onChange={(event) => {
                  setShowFakeField(event.target.checked)
                }}
              />
            }
            label="상세 필드 보기"
          />

          <div style={{ padding: 10 }}>전체베팅머니 : {totalBetMoney.toLocaleString()}</div>
          <div style={{ padding: 10 }}>전체승리머니 : {totalWinMoney.toLocaleString()}</div>
          <div style={{ padding: 10 }}>전체 승률 : {(totalWinRate * 100).toLocaleString() + ' %'}</div>
        </div>
        <DataGrid
          style={{ width: '100%', height: '100%' }}
          components={{ LoadingOverlay: LinearProgress }}
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
          rows={bettings}
          columns={logColumes}
          pageSize={100}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          loading={loading}
        />
        <SettleDialog
          open={openSettleDialog}
          betting={selectedBetting}
          onClose={(updated: boolean) => {
            setOpenSettleDialog(false)
            if (updated) setNeedUpdate(true)
          }}
        />
        <ModifyDialog
          open={openModifyDialog}
          betting={selectedBetting}
          onClose={(setObj) => {
            setOpenModifyDialog(false)
            if (Object.keys(setObj).length > 0) {
              const updatedBetting = bettings.find((value) => value._id === selectedBetting?._id)
              if (updatedBetting != null) {
                Object.assign(updatedBetting, setObj)

                setBettings([...bettings])
              }
            }
          }}
        />
        <BetDetailDialog
          open={openDetailDialog}
          betId={selectedBetting?._id ?? ''}
          gameType={selectedBetting?.gameType ?? ''}
          onClose={(updated: boolean) => {
            setOpenDetailDialog(updated)
          }}
        />
        <FakeDetailDialog
          open={openFakeDetailDialog}
          roundId={selectedBetting?.fakeRoundId ?? ''}
          username={(selectedBetting?.agentCode ?? '') + (selectedBetting?.userId ?? '')}
          onClose={(updated: boolean) => {
            setOpenFakeDetailDialog(updated)
          }}
        />
      </LocalizationProvider>
    </div>
  )
}

export default FakeBettingSearch
